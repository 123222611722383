import React from "react";
import { CurvyArrowIcon, TickIcon } from "../SvgIcons";
import { PlanDetails } from "../../types/planTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { handlePriceButtonClick } from "../../utils/pricingNavigationUtils";

const PlanCard: React.FC<{
  planDetails: PlanDetails;
  onClick: () => void;
}> = ({ planDetails, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ccid = searchParams.get("ccid");
  const pricingDetails = JSON.parse(
    localStorage.getItem("pricingdetail") || "[]",
  );
  const country = pricingDetails[0]?.country || "US";

  const handlePriceClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handlePriceButtonClick(e, navigate, ccid, planDetails);
  };

  return (
    <>
      <div
        className={`${
          planDetails.settings.highlight && planDetails.settings.tag
            ? "bg-special-card-gradient font-plusJakartaSans xl:-mt-[54px]"
            : "mt-0"
        } cursor-pointer rounded-[20px] px-0.5 pb-0.5 lg:pb-0`}
        onClick={onClick}
      >
        {planDetails.settings.highlight && planDetails.settings.tag && (
          <div className="flex items-center justify-center space-x-2 py-3 text-center text-xl font-semibold text-white">
            <CurvyArrowIcon /> <p>{planDetails.settings.tag}</p>
          </div>
        )}
        <div
          className={`relative min-h-[430px] cursor-pointer rounded-[20px] border-[1px] border-stone-700 bg-[#181818] bg-card-gradient px-5 py-6`}
        >
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/PricingPage/puzzle-piece.webp`}
            alt="Card background"
            className="absolute bottom-0 right-0 z-0 object-cover opacity-20"
          />
          <div className="relative z-20">
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-semibold text-white">
                {planDetails.title}
              </h3>

              {/* {planDetails.pricing.discount !== 0 && (
                <p className="rounded-full bg-[#D07C32] px-3 py-1.5 text-[15px] font-medium text-white">
                  - {planDetails.pricing.discount}% off
                </p>
              )} */}
            </div>

            {(country === "IN" || country !== "IN") &&
            planDetails.pricing[country === "IN" ? "IN" : "US"] ? (
              <p
                className={`pt-3 text-lg font-bold text-silverChalice line-through transition-opacity duration-300 ${
                  planDetails.pricing.discount === 0
                    ? "opacity-0"
                    : "opacity-100"
                }`}
              >
                {country === "IN"
                  ? `₹${planDetails.pricing.IN}`
                  : `$${planDetails.pricing.US}`}
              </p>
            ) : null}

            {(country === "IN" || country !== "IN") &&
            planDetails.pricing[country === "IN" ? "IN" : "US"] ? (
              <div className="flex items-end gap-x-1">
                <p className="text-2xl font-bold text-white">
                  {country === "IN"
                    ? `₹${planDetails.pricing.IN - (planDetails.pricing.IN * planDetails.pricing.discount) / 100}`
                    : `$${planDetails.pricing.US - (planDetails.pricing.US * planDetails.pricing.discount) / 100}`}
                </p>
                <p className="text-lg font-medium text-mercury">
                  / {planDetails.pricing.validForInDays} days{" "}
                  <span className="ml-1 text-sm text-silverChalice">
                    {" "}
                    (Inc. Tax)
                  </span>
                </p>
              </div>
            ) : null}

            {planDetails.title === "Business" && (
              <p className="mb-1 mt-8 text-2xl font-bold text-white">
                Customized Plan
              </p>
            )}

            {planDetails.title === "Free" && (
              <div className="mb-1 mt-8 flex items-end gap-x-1">
                <p className="text-2xl font-bold text-white">
                  {country === "IN" ? `₹0` : `$0`}
                </p>
                <p className="text-lg font-medium text-mercury">/ 30 days</p>
              </div>
            )}

            <button
              className={`mb-1 mt-4 w-full rounded-full py-2.5 font-semibold text-white transition ${
                planDetails.settings.highlight
                  ? "bg-[#F8A359] hover:bg-opacity-90"
                  : "bg-[#3C3631] hover:bg-opacity-70"
              }`}
              onClick={handlePriceClick}
            >
              {planDetails.settings.buttonText}
            </button>
            <p className="my-3 text-sm text-[#c5c5c5]">
              {planDetails.description}
            </p>
            {planDetails.title === "Business" ? (
              <p className="my-3 text-sm text-[#c5c5c5]">Markets We Serve:</p>
            ) : (
              <p className="my-3 text-sm text-[#c5c5c5]">
                This Subscription includes:
              </p>
            )}
            <ul className="space-y-2 text-sm text-[#c5c5c5]">
              {/* Show the first three features */}
              {planDetails.features?.slice(0, 3).map((feature, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <TickIcon width={18} height={18} />
                  <p>{feature}</p>
                </li>
              ))}

              {/* Display the remaining number of features */}
              {planDetails.features && planDetails.features.length > 3 && (
                <li className="text-silverChalice">
                  + {planDetails.features.length - 3} more...
                </li>
              )}
            </ul>
          </div>
          {planDetails.settings.freeTrialMonth && (
            <div className="flex items-center justify-center">
              <div
                className={`absolute bottom-[-3%] rounded-full bg-white bg-gradient-to-tr from-[#31733C] to-[#274F86] px-4 py-1 text-center text-sm font-medium text-mercury`}
              >
                Get one month free trial
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PlanCard;
