import React, { useEffect, useState } from "react";
import PlanCard from "./PlanCard";
import PlanModal from "./PlanModal";
import { PlanDetails } from "../../types/planTypes";
import { businessPlan } from "../../data/businessPlan";
import { freePlan } from "../../data/freePlan";
import { useDispatch, useSelector } from "react-redux";
import { setPlan } from "../../features/planSlice";
import { AppDispatch } from "../../app/store";

const Plan: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlanState] = useState<PlanDetails | null>(
    null,
  );
  const [trialAvail, setTrialAvail] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { allPlans = [] } = useSelector((state: any) => state.plan);

  useEffect(() => {
    const trialStatus = localStorage.getItem("trialAvail");
    setTrialAvail(trialStatus); // Update the state with the value from localStorage
  }, []); // Empty dependency array means this runs once on component mount

  const handlePlanClick = (plan: PlanDetails) => {
    setSelectedPlanState(plan);
    dispatch(setPlan(plan));
    setModalOpen(true);
  };

  if (trialAvail === null) {
    return <div></div>;
  }

  return (
    <section className="py-14 lg:pb-20 xl:pt-32" id="plans">
      <div className="mx-auto max-w-[90%] font-plusJakartaSans lg:max-w-[90%]">
        {/* <PlanToggle active={pricingType} onToggle={setPricingType} /> */}
        <div
          className={`grid grid-cols-1 gap-10 xl:gap-x-3 ${trialAvail === "true" ? "xl:grid-cols-4" : "xl:grid-cols-3"}`}
        >
          {trialAvail === "true" && (
            <PlanCard
              planDetails={freePlan}
              onClick={() => handlePlanClick(freePlan)}
            />
          )}
          {allPlans.map((subscription: PlanDetails) => (
            <PlanCard
              key={subscription._id}
              planDetails={subscription}
              onClick={() => handlePlanClick(subscription)}
            />
          ))}
          <PlanCard
            planDetails={businessPlan}
            onClick={() => handlePlanClick(businessPlan)}
          />
        </div>
      </div>

      {selectedPlan && (
        <PlanModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          planDetails={selectedPlan}
          setSelectedPlan={setSelectedPlanState}
          subscriptions={allPlans}
          allPlanTitles={allPlans.map(
            (subscription: PlanDetails) => subscription.title,
          )}
        />
      )}
    </section>
  );
};

export default Plan;
