import Navbar from "../components/Navbar";
import FooterSection from "../components/Footer/Footer";

interface TermItemProps {
  title: string;
  paragraph: string;
  listItems?: string[];
  index?: number;
}

const TermItem: React.FC<TermItemProps> = ({
  title,
  paragraph,
  listItems,
  index,
}) => {
  return (
    <div className="mb-8 lg:mb-10">
      <h3 className="mb-2 mt-8 font-epilogue text-xl font-semibold text-mercury lg:mb-3 lg:mt-10 lg:text-2xl">
        {index && `${index}.`} {title}
      </h3>
      <p className="font-plusJakartaSans text-silverChalice lg:text-lg">
        {paragraph}
      </p>
      {listItems && (
        <ul>
          {listItems.map((item, index) => (
            <li
              key={index}
              className="ml-8 list-disc py-1 font-plusJakartaSans text-silverChalice lg:text-lg"
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TermsOfUse: React.FC = () => {
  return (
    <div className="relative bg-codGray font-plusJakartaSans text-mercury">
      <div className="headerBackground absolute inset-0 z-0 h-screen"></div>
      <section className="relative z-10">
        <Navbar />
        <div className="mx-6 mb-20 mt-14 sm:mx-10 md:mx-12 md:mt-16 xl:mx-20 xl:mb-32">
          <h1 className="mx-auto w-[80%] text-center font-epilogue text-3xl font-bold leading-snug md:my-5 md:w-full md:text-4xl xl:text-5xl">
            Workplay Studio Private Limited
          </h1>
          <h1 className="text-center font-epilogue text-3xl font-bold leading-snug md:my-5 md:text-4xl xl:text-5xl">
            – Website Terms and Conditions
          </h1>
          <div className="my-14 text-silverChalice md:my-20 md:text-[17px] xl:text-lg">
            <p>
              Welcome to the Workplay Studio website (the “Site”), operated by
              Workplay Studio Private Limited (“Workplay,” “we,” “our,” or
              “us”). By accessing or using the Site, you agree to comply with
              and be bound by these Terms and Conditions (“Terms”). Please read
              these Terms carefully before using the Site.
            </p>
            <TermItem
              title="Acceptance of Terms"
              paragraph="By accessing and using the Site, you acknowledge that you have
              read, understood, and agree to be bound by these Terms, including
              any future modifications. If you do not agree with these Terms,
              you should not use the Site."
              index={1}
            />
            <TermItem
              title="Changes to Terms"
              paragraph="We reserve the right to update or change these Terms at any time. Any changes will be posted on this page with the updated date. Your continued use of the Site following the posting of changes constitutes your acceptance of those changes."
              index={2}
            />
            <TermItem
              title="Use of the Site"
              paragraph="You agree to use the Site for lawful purposes only. You are prohibited from using the Site:"
              listItems={[
                "To engage in any illegal activity or violate applicable laws.",
                "To infringe on intellectual property rights or rights of privacy.",
                "To introduce harmful software such as viruses or malware.",
                "To interfere with the operation or security of the Site.",
              ]}
              index={3}
            />{" "}
            <TermItem
              title="Intellectual Property"
              paragraph="All content on the Site, including text, images, logos, videos, designs, and trademarks, is the property of Workplay or its licensors and is protected by intellectual property laws. You may not copy, modify, distribute, or reproduce any content from the Site without prior written consent from Workplay."
              index={4}
            />
            <TermItem
              title="User-Generated Content"
              paragraph="If you submit any content to the Site (e.g., comments, feedback, or suggestions), you grant Workplay a worldwide, royalty-free, perpetual, and irrevocable license to use, modify, and distribute that content for any purpose related to the Site."
              index={5}
            />
            <TermItem
              title="Privacy and Data Protection"
              paragraph="Your use of the Site is governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. Please review our Privacy Policy for more details."
              index={6}
            />
            <TermItem
              title="Links to Third-Party Websites"
              paragraph="The Site may contain links to third-party websites. These links are provided for convenience only. Workplay is not responsible for the content, accuracy, or privacy practices of third-party sites. You access them at your own risk."
              index={7}
            />
            <TermItem
              title="Disclaimers"
              paragraph={`The Site is provided "as is" without any warranties, either express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose. We do not guarantee that the Site will be uninterrupted or error-free.`}
              index={8}
            />
            <TermItem
              title="Limitation of Liability"
              paragraph="To the fullest extent permitted by law, Workplay shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of the Site or any content therein."
              index={9}
            />
            <TermItem
              title="Indemnity"
              paragraph="You agree to indemnify and hold harmless Workplay and its affiliates, employees, agents, and partners from any claims, losses, liabilities, or expenses (including legal fees) arising out of your use of the Site or violation of these Terms."
              index={10}
            />
            <TermItem
              title="Governing Law"
              paragraph="These Terms shall be governed by and construed in accordance with the laws of Jurisdiction of India. Any disputes arising from these Terms or your use of the Site shall be subject to the exclusive jurisdiction of the courts in Jurisdiction of India."
              index={11}
            />
            <TermItem
              title="Termination"
              paragraph="Workplay may suspend or terminate your access to the Site at any time without notice if you violate these Terms. Upon termination, all provisions of these Terms that by their nature should survive will remain in effect."
              index={12}
            />
            <TermItem
              title="Contact Information"
              paragraph="If you have any questions or concerns about these Terms, please contact us at:"
              index={13}
            />
            <p className="-mt-5 font-semibold md:text-[17px] xl:text-lg">
              Workplay Studio Private Limited
            </p>
            <p className="my-1.5 md:text-[17px] xl:text-lg">
              No.13-14, 2nd floor, Kothnur Main Rd, RBI Layout, JP Nagar 7th
              Phase, Bengaluru - 560078
            </p>
            <p className="my-1.5 md:text-[17px] xl:text-lg">
              Email: info@workplay.digital
            </p>
            <p className="my-1.5 md:text-[17px] xl:text-lg">
              Phone: +91 97428 47450
            </p>
            <p className="mt-9 md:text-[17px] xl:text-lg">
              By using this Site, you acknowledge that you have read and agree
              to these Terms and Conditions.
            </p>
            <p className="mt-4 text-mercury md:text-[17px] xl:text-lg">
              Updated by The Workplay Studio Pvt Ltd Team on Feb. 15, 2025
            </p>
          </div>
        </div>
        <FooterSection />
      </section>
    </div>
  );
};

export default TermsOfUse;
