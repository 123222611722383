import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "../../styles/SignUp.css";
import countriesData from "../../assets/countriesData.json";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import LazyLoadImages from "../LazyLoadImages";
import axios from "axios";
import { ArrowLeft, Eye, EyeOff } from "react-feather";
import "../../styles/Contact.css";

interface CountryInfo {
  country: string;
  nationality: string;
}
interface SignUpEmailProps {
  onGoBack: () => void;
  displayInfoMessage: (message: string) => void;
}

const { REACT_APP_API_URL, REACT_APP_S3_URL } = process.env;

const SignUpEmail: React.FC<SignUpEmailProps> = ({
  onGoBack,
  displayInfoMessage,
}) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const fromValue = urlParams.get("from");
  const campaignId = urlParams.get("gameId");
  const contextValue = useContext(LoginContext);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nationality, setNationality] = useState("");
  const [phoneNumberWithoutDialCode, setPhoneNumberWithoutDialCode] =
    useState("");
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isContactFocused, setIsContactFocused] = useState(false);

  const handleFirstNameFocus = () => setIsFirstNameFocused(true);
  const handleFirstNameBlur = () => {
    setIsFirstNameFocused(false);
  };

  const handleLastNameFocus = () => setIsLastNameFocused(true);
  const handleLastNameBlur = () => {
    setIsLastNameFocused(false);
  };

  const handleEmailFocus = () => setIsEmailFocused(true);
  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const handlePasswordFocus = () => setIsPasswordFocused(true);
  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleContactFocus = () => setIsContactFocused(true);
  const handleContactBlur = () => {
    setIsContactFocused(false);
  };

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    password: "",
    isdCode: "",
    agreeToTerms: false,
  });

  // Name validation
  const handleFirstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const alphaRegex = /^[a-zA-Z\s]*$/;

    if (name === "fname") {
      if (!value.trim()) {
        toast.error("Please enter your first name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
      } else if (!alphaRegex.test(value)) {
        toast.error("Name should contain only alphabets", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLastChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const alphaRegex = /^[a-zA-Z\s]*$/;

    if (name === "lname") {
      if (!value.trim()) {
        toast.error("Please enter your last name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
      } else if (!alphaRegex.test(value)) {
        toast.error("Name should contain only alphabets", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // PhoneNumber validation
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Find the country info based on the country name
    const countryInfo: CountryInfo | undefined = countriesData.find(
      (info: CountryInfo) => info.country === country.name,
    );

    if (!countryInfo) {
      console.error(`Country info not found for name: ${country.name}`);
      return;
    }

    setNationality(countryInfo.nationality);
    setFormData({
      ...formData,
      isdCode: country.dialCode,
    });

    const dialCode = country.dialCode;

    const phoneNumberWithoutDialCodeValue = value.replace(dialCode, "");
    setPhoneNumberWithoutDialCode(phoneNumberWithoutDialCodeValue);
    setFormData((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  // Email
  const handleEmailChange = (email: string) => {
    setFormData({
      ...formData,
      email: email,
    });
  };

  // Password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Password validation
  const handlePasswordChange = (password: string) => {
    // Set form data
    setFormData({
      ...formData,
      password: password,
    });

    // Validation regular expressions
    const digitRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    // Check if the entered password meets all constraints
    const isPasswordValid =
      password.length >= 8 &&
      digitRegex.test(password) &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password);

    // Set password validation flag
    setPasswordValid(isPasswordValid);

    // Set password error message if password is present but doesn't meet criteria
    if (password && !isPasswordValid) {
      setPasswordErrorMessage(
        "Password must be at least 8 characters long, contain at least 1 digit, 1 uppercase letter, and 1 special character",
      );
    } else if (!password) {
      // If password field is empty, display "Please enter a valid password" error
      setPasswordErrorMessage("Please enter a valid password");
    } else {
      // Clear the error message if password is valid
      setPasswordErrorMessage("");
    }
  };

  // Checkbox for agree to terms and conditions
  const handleCheckboxChange = () => {
    const agreeToTerms = !formData.agreeToTerms;

    // Update form data
    setFormData({
      ...formData,
      agreeToTerms: agreeToTerms,
    });

    if (!agreeToTerms) {
      toast.error("Please agree to the Terms and Conditions", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    }
  };
  const redirectToGame = (token: any) => {
    if (campaignId && fromValue) {
      navigate(
        `/dashboard/campaigns/create?gameId=${encodeURIComponent(campaignId)}&fromValue=${fromValue}`,
      );
    }
  };

  const handleSubmit = async () => {
    let isError = false;
    // Check if any of the required fields are empty
    if (
      !formData.fname &&
      !formData.lname &&
      !formData.email &&
      !formData.password &&
      !formData.agreeToTerms
    ) {
      toast.error("Please fill in all the fields", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      isError = true;

      return;
    } else {
      if (!formData.fname) {
        toast.error("Please enter your first name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }
      if (!formData.lname) {
        toast.error("Please enter your last name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }

      if (formData.phone) {
        if (
          nationality === "Indian" &&
          phoneNumberWithoutDialCode.replace(/\D/g, "").length !== 10
        ) {
          toast.error("Please enter a valid phone number", {
            autoClose: 2000,
            className: "toastify-toast",
          });
          return;
        }
      }

      if (!formData.email) {
        toast.error("Please enter your email address", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }

      if (formData.email) {
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
          toast.error("Please enter a valid email address", {
            autoClose: 2000,
            className: "toastify-toast",
          });
          return;
        }
      }

      if (!formData.password.trim()) {
        toast.error("Please enter your password", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }

      // Check if the password is empty or doesn't meet criteria
      if (!passwordValid) {
        toast.error(
          "Password must be at least 8 characters long, contain at least 1 digit, 1 uppercase letter, and 1 special character",
          {
            autoClose: 3000,
            className: "toastify-toast",
          },
        );
        return;
      }

      if (!formData.agreeToTerms) {
        toast.error("Please agree to the Terms and Conditions", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        isError = true;
      }
    }

    setFormSubmitted(true);
    const URL = `${REACT_APP_API_URL}/auth/signup`;

    if (!isError) {
      if (!URL) {
        return;
      }
      try {
        const response = await axios.post(
          URL,
          {
            firstName: formData.fname,
            lastName: formData.lname,
            email: formData.email,
            ...(formData.isdCode && { isdCode: "+" + formData.isdCode }),
            ...(phoneNumberWithoutDialCode && {
              phoneNumber: phoneNumberWithoutDialCode,
            }),
            password: formData.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data.success === true) {
          contextValue?.login();

          localStorage.setItem("xSudTzMYmd", response.data.data.accessToken);
          if (formSubmitted) {
            setFormData({
              fname: "",
              lname: "",
              phone: "",
              email: "",
              password: "",
              isdCode: "",
              agreeToTerms: false,
            });
          }

          if (campaignId && fromValue) {
            redirectToGame(response.data.data.accessToken);
          } else {
            setTimeout(() => {
              navigate("/dashboard/campaigns", { replace: true });
            }, 1000);
          }
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          autoClose: 2000,
          className: "toastify-toast",
        });
        contextValue?.logout();
        console.error("Error creating user:", error);
      }
    }
  };

  const handleBackButtonClick = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  const handleLogoRedirect = () => {
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      const token = localStorage.getItem("xSudTzMYmd");
      if (fromValue && campaignId) {
        redirectToGame(token);
      } else navigate("/", { replace: true });
    }
  }, []);

  return (
    <>
      <div
        style={{
          pointerEvents: localStorage.getItem("xSudTzMYmd") ? "none" : "auto",
        }}
      >
        <div className="input-wrapper relative flex min-h-screen w-full items-center overflow-x-hidden bg-codGray py-6 md:py-0">
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/LoginPage/Ellipse.webp`}
            alt="Ellipse"
            className="absolute top-0 z-0"
          />
          <div className="z-100 w-full items-center justify-center px-6 md:flex md:w-[55%] xl:w-[40%]">
            <div>
              <LazyLoadImages
                className="mb-8 h-6 cursor-pointer xl:h-7"
                src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
                alt="workplay-logo"
                onClick={handleLogoRedirect}
              />{" "}
              <div
                className="mb-4 flex w-fit cursor-pointer items-center justify-start font-plusJakartaSans text-silverChalice"
                onClick={handleBackButtonClick}
              >
                <ArrowLeft />
                <p className="ml-2">back</p>
              </div>
              <div className="relative">
                {" "}
                <h2 className="text-left font-epilogue text-3xl font-bold text-mercury lg:text-left lg:text-4xl">
                  Ready to Play?
                </h2>
                <LazyLoadImages
                  src={`${REACT_APP_S3_URL}/website-assets/LoginPage/sparkle.webp`}
                  alt="sparkle"
                  className="absolute right-[0%] top-[-0%] h-8 lg:right-[-6%] lg:h-auto"
                />
              </div>
              <p className="m-auto mb-6 mt-2 text-left font-epilogue text-[17px] font-medium text-silverChalice lg:text-left">
                Start Building Your Game!
              </p>{" "}
              <form
                id="form"
                className="mx-auto flex flex-col font-semibold text-black"
              >
                <div className="flex flex-col space-y-5">
                  <div className="flex w-full items-center justify-between space-x-2">
                    <div className="relative w-full">
                      <label
                        htmlFor="fname"
                        className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                          isFirstNameFocused
                            ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                            : formData.fname
                              ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                              : "text-mercury"
                        }`}
                      >
                        First Name{" "}
                        <span
                          className={`${isFirstNameFocused ? "text-tanHide" : "text-red-400"}`}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder=""
                        value={formData.fname}
                        onChange={handleFirstChange}
                        onFocus={handleFirstNameFocus}
                        onBlur={handleFirstNameBlur}
                        required
                        className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:mr-[4px] focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                      />
                    </div>
                    <div className="relative w-full">
                      <label
                        htmlFor="lname"
                        className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                          isLastNameFocused
                            ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                            : formData.lname
                              ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                              : "text-mercury"
                        }`}
                      >
                        Last Name{" "}
                        <span
                          className={`${isLastNameFocused ? "text-tanHide" : "text-red-400"}`}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="lname"
                        name="lname"
                        placeholder=""
                        className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:ml-[3.5px] focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                        value={formData.lname}
                        onChange={handleLastChange}
                        onFocus={handleLastNameFocus}
                        onBlur={handleLastNameBlur}
                        required
                      />
                    </div>
                  </div>
                  <div className="relative w-full">
                    <label
                      htmlFor="contactNumber"
                      className={`absolute left-3 top-3.5 z-100 ml-24 transform transition-all duration-300 ${
                        isContactFocused
                          ? "ml-0 -translate-y-7 bg-codGray px-1 text-tanHide"
                          : formData.phone
                            ? "ml-0 -translate-y-7 bg-codGray  px-1 text-mercury duration-0"
                            : "text-mercury"
                      }`}
                    >
                      Phone Number
                    </label>
                    <div className="contact-phone-wrapper z-0">
                      <PhoneInput
                        country={"in"}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputProps={{ id: "contactNumber" }}
                        onFocus={handleContactFocus}
                        onBlur={handleContactBlur}
                      />
                    </div>
                  </div>
                  <div className="relative w-full">
                    <label
                      htmlFor="email"
                      className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                        isEmailFocused
                          ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                          : formData.email
                            ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                            : "text-mercury"
                      }`}
                    >
                      Email Address{" "}
                      <span
                        className={`${isEmailFocused ? "text-tanHide" : "text-red-400"}`}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder=""
                      value={formData.email}
                      onChange={(e) => handleEmailChange(e.target.value)}
                      onFocus={handleEmailFocus}
                      onBlur={handleEmailBlur}
                      required
                      className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                    />
                  </div>
                  <div className="relative w-full">
                    {" "}
                    <label
                      htmlFor="password"
                      className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                        isPasswordFocused
                          ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                          : formData.password
                            ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                            : "text-mercury"
                      }`}
                    >
                      Password{" "}
                      <span
                        className={`${isPasswordFocused ? "text-tanHide" : "text-red-400"}`}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      onFocus={handlePasswordFocus}
                      onBlur={handlePasswordBlur}
                      placeholder=""
                      required
                      className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                    />
                    {showPassword ? (
                      <EyeOff
                        className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <Eye
                        className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                {formSubmitted && passwordErrorMessage && (
                  <p className="pb-1 font-normal text-red-500">
                    {passwordErrorMessage}
                  </p>
                )}
                <div className="mt-4 flex items-center">
                  <label
                    htmlFor="agreeToTerms"
                    className="items-center space-x-2.5 font-plusJakartaSans font-medium text-silverChalice xsm:flex"
                  >
                    <input
                      type="checkbox"
                      id="agreeToTerms"
                      name="agreeToTerms"
                      checked={formData.agreeToTerms}
                      onChange={handleCheckboxChange}
                      className="relative mr-2 w-[18px] cursor-pointer appearance-none rounded border border-none border-mercury bg-mercury bg-opacity-10 checked:border-mercury checked:bg-tanHide checked:opacity-100 checked:after:absolute checked:after:right-1 checked:after:text-xs checked:after:text-white checked:after:content-['✓']"
                    />
                    Agree to our
                    <Link
                      to="/privacy-policy"
                      className="underline-link relative ml-1.5 scale-105 hover:opacity-80"
                    >
                      <strong className="win relative font-epilogue font-semibold">
                        Privacy Policy & conditions
                      </strong>
                    </Link>
                  </label>
                </div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="headerBtn relative my-5 w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-xl"
                >
                  Create account
                </button>
              </form>
              <p className="mb-16 font-plusJakartaSans font-medium text-silverChalice sm:mb-0">
                Already have an account?
                <Link
                  to={
                    fromValue && campaignId
                      ? `/login?from=${fromValue}&gameId=${campaignId}`
                      : "/login"
                  }
                  className="underline-link relative ml-1 scale-105 hover:opacity-80"
                >
                  <strong className="win relative font-epilogue font-semibold">
                    Sign In
                  </strong>
                </Link>{" "}
              </p>
            </div>
          </div>{" "}
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/authPages/pattern.webp`}
            alt="pattern"
            className="absolute bottom-0 right-0 -z-0"
          />
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/authPages/yellowEllipse.webp`}
            alt="Ellipse"
            className="absolute bottom-0 right-0 z-0 h-full"
          />
          <div className="z-0 hidden md:block md:w-[45%] xl:w-[60%]">
            <div className="flex items-center justify-center">
              <LazyLoadImages
                className="w-auto px-6 md:px-10 lg:px-20"
                src={`${REACT_APP_S3_URL}/website-assets/authPages/signUp.webp`}
                alt="signUp"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignUpEmail;
