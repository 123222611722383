import { useEffect, useState, useContext } from "react";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import LazyLoadImages from "../LazyLoadImages";
import { useLocation, useNavigate } from "react-router-dom";

interface NavItem {
  label: string;
  url: string;
  onClick?: () => void;
}

const { REACT_APP_S3_URL } = process.env;

const DashBoardHeader: React.FC = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [screen, setScreen] = useState(false);
  const [userName, setUserName] = useState<string | null>(null);
  const contextValue = useContext(LoginContext);
  const location = useLocation();

  useEffect(() => {
    const checkUserName = () => {
      const fname = sessionStorage.getItem("fname");
      const lname = sessionStorage.getItem("lname");
      if (fname && lname) {
        setUserName(`${fname} ${lname}`);
      }
    };
    checkUserName();
    const intervalId = setInterval(checkUserName, 100);
    return () => clearInterval(intervalId);
  }, []);

  const handleScroll = () => {
    setIsTop(window.pageYOffset === 0);
  };

  useEffect(() => {
    const content = document.getElementById("mainContent");
    if (menuOpen) {
      document.body.style.overflow = "hidden";
      if (content) content.classList.add("hidden-content");
    } else {
      if (content) content.classList.remove("hidden-content");
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [menuOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogoRedirect = () => {
    window.location.href = "/";
  };

  const handleLogout = () => {
    localStorage.removeItem("xSudTzMYmd");
    contextValue?.logout();
    sessionStorage.removeItem("ImgLink");
    sessionStorage.removeItem("fname");
    sessionStorage.removeItem("lname");
    sessionStorage.removeItem("collapse");
    sessionStorage.removeItem("email");
    setMenuOpen(false);
    localStorage.clear();
    navigate("/");
  };

  const NavItems: NavItem[] = [
    { label: "Home", url: "/" },
    { label: "Projects", url: "/dashboard/campaigns" },
    { label: "Statistics", url: "/dashboard/statistics" },
    { label: "View Plans", url: "/pricing" },
    { label: "Profile", url: "/dashboard/profile" },
    { label: "Logout", url: "/", onClick: handleLogout },
  ];

  return (
    <div className="flex h-20 w-full items-center justify-center bg-black px-6 drop-shadow-sm lg:h-20 lg:bg-white">
      <div className="flex w-full justify-between lg:hidden">
        <div className="flex w-full items-center justify-between">
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
            className="h-[23px] cursor-pointer lg:hidden"
            alt="workplay"
            onClick={handleLogoRedirect}
          />
          <button onClick={() => setMenuOpen(!menuOpen)}>
            <FontAwesomeIcon
              icon={menuOpen ? faClose : faBars}
              className={
                menuOpen
                  ? "ml-2 h-7 text-white transition ease-in"
                  : "h-7 text-white transition ease-in-out"
              }
            />
          </button>
        </div>
        {menuOpen && (
          <div className="absolute left-0 top-20 h-screen w-full bg-[#1e1e1e] pt-20 text-lg text-gray-200 lg:hidden">
            <div className="flex flex-col items-center p-4">
              {NavItems.map((item, index) => (
                <button
                  key={index}
                  className={`mb-6 text-white hover:text-gray-300 ${
                    item.label === "Logout" ? "text-green-500 underline" : ""
                  }`}
                  onClick={() => {
                    setMenuOpen(false);
                    if (item.onClick) {
                      item.onClick();
                    } else {
                      navigate(item.url);
                    }
                  }}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      {userName ? (
        <div className="hidden w-full lg:block">
          {location.pathname === "/dashboard" ? (
            <div>
              <p className="font-poppins text-base font-normal">Hello,</p>
              <p className="font-poppins text-lg font-medium">{userName}</p>
            </div>
          ) : (
            <p className="font-poppins text-xl font-medium uppercase tracking-wider">
              {location.pathname.includes("/dashboard")
                ? location.pathname.includes("/dashboard/campaigns")
                  ? location.pathname.includes("/dashboard/campaigns/create")
                    ? "Create project"
                    : "Projects"
                  : location.pathname.includes("/dashboard/gamedetails")
                    ? "Project Details"
                    : location.pathname.includes("/dashboard/statistics")
                      ? "Statistics"
                      : location.pathname
                          .split("/dashboard")[1]
                          .substring(1)
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())
                : location.pathname}
            </p>
          )}
        </div>
      ) : null}
      <header className="hidden w-full justify-end text-black lg:flex lg:flex-row">
        {/* <div className="hidden lg:flex lg:flex-row">
          <div className="hidden flex-row items-center justify-center lg:flex">
            <div className="cursor-pointer">
              <div
                data-tooltip-id="info"
                data-tooltip-content="Help"
                data-tooltip-delay-show={1000}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-[#e9e9e9] shadow-2xl hover:scale-[102%]"
              >
                <img
                  className="h-6"
                  src={`${REACT_APP_S3_URL}/assets/icons/info.webp`}
                  alt="info"
                />
              </div>
            </div>
            <div className="cursor-pointer">
              <div
                data-tooltip-id="Notification"
                data-tooltip-content="Notification"
                data-tooltip-delay-show={1000}
                className="ml-4 flex h-12 w-12 items-center justify-center rounded-full bg-[#e9e9e9] shadow-2xl hover:scale-[102%]"
              >
                <img
                  className="h-[22px]"
                  src={`${REACT_APP_S3_URL}/assets/icons/notification.webp`}
                  alt="Notification-bell"
                />
              </div>
            </div>
            <Tooltip
              id="info"
              className="custom-tooltip"
              noArrow
              style={{ fontSize: "14px", fontFamily: "Poppins" }}
            />
            <Tooltip
              id="Notification"
              className="custom-tooltip"
              noArrow
              style={{ fontSize: "14px", fontFamily: "Poppins" }}
            />
          </div>
        </div> */}
      </header>
    </div>
  );
};

export default DashBoardHeader;
