import React from "react";
import Card from "./Card";

const CardGrid: React.FC = () => {
  return (
    <div>
      <h3 className="py-8 font-epilogue text-2xl font-semibold sm:py-10 md:text-3xl lg:py-16 lg:text-4xl">
        See what we provide
      </h3>
      <div className="grid justify-center gap-8 md:grid-cols-2 xl:grid-cols-3 xl:gap-16">
        <Card
          title="Spin the Wheel"
          description="Engage audiences with thrilling, customizable rewards!"
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/spin.webp`}
          buttonLinkPlay="https://spin-the-wheel.workplay.digital/"
          buttonLinkCustomize={`${process.env.REACT_APP_CUSTOM_SPIN_URL}`}
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/blue.webp`}
          link="/game/spin-the-wheel"
        />

        <Card
          title="Quiz"
          description="Challenge friends and test knowledge with our versatile quiz."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/quiz.webp`}
          buttonLinkPlay="https://quiz.workplay.digital/"
          buttonLinkCustomize={`${process.env.REACT_APP_CUSTOM_QUIZ_URL}`}
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/red.webp`}
          link="/game/quiz"
        />

        <Card
          title="Memory Card"
          description="Match pairs and enhance engagement with our Memory Card."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/memoryCard.webp`}
          buttonLinkPlay="https://memory-game.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/yellow.webp`}
          link="/game/memory-card"
        />

        <Card
          title="Find the Object"
          description="Discover hidden objects and captivate audiences with Find the Object."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/findTheObject.webp`}
          buttonLinkPlay="https://find-the-object.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/yellow.webp`}
          link="/game/find-the-object"
        />

        <Card
          title="Jigsaw Puzzle"
          description="Challenge and engage your audience with our Jigsaw Puzzle."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/puzzle.webp`}
          buttonLinkPlay="https://jigsaw-puzzle.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/green.webp`}
          link="/game/jigsaw-puzzle"
        />

        <Card
          title="Slot Machine"
          description="Spin for fun and rewards with our exciting Slot Machine game."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/slot.webp`}
          buttonLinkPlay="https://slot-machine.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/blue.webp`}
          link="/game/slot-machine"
        />

        <Card
          title="Tic Tac Toe"
          description="Elevate the challenge with our interactive Tic-Tac-Toe game!"
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/puzzle.webp`}
          buttonLinkPlay="https://tic-tac-toe.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/yellow.webp`}
          link="/game/tic-tac-toe"
        />

        <Card
          title="Spot the Difference"
          description="Test your observation skills with our thrilling Spot the Difference game."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/spotTheDifference.webp`}
          buttonLinkPlay="https://spot-the-difference.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/green.webp`}
          link="/game/spot-the-difference"
        />

        <Card
          title="Roll the Dice"
          description="Spin excitement and create loyalty with our Roll The Dice game."
          imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/cards/rollTheDice.webp`}
          buttonLinkPlay="https://roll-the-dice.workplay.digital/"
          imageBgSrc={`${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/red.webp`}
          link="/game/roll-the-dice"
        />
      </div>
    </div>
  );
};

export default CardGrid;
