export const freePlan = {
  _id: "free",
  title: "Free",
  description: "Start now with a free trial and see the results with Workplay.",
  pricing: {
    US: 0,
    IN: 0,
    isFree: false,
    discount: 0,
    validForInDays: 0,
  },
  features: [
    "30 days validity",
    "1 user account",
    "1 customizable game",
    "Dashboard with game analytics",
    "Unlimited players",
    "Leaderboard available",
  ],
  settings: {
    freeTrialMonth: false,
    buttonText: "Start 30-day free trial",
    highlight: false,
    tag: "",
  },
};
