import { NavigateFunction } from "react-router-dom";

export const handlePriceButtonClick = (
  e: React.MouseEvent<HTMLButtonElement>,
  navigate: NavigateFunction,
  ccid: string | null,
  planDetails: any,
) => {
  e.stopPropagation();

  const token = localStorage.getItem("xSudTzMYmd");

  // Handle redirection based on plan title
  const redirectToCampaigns = (planId: string | null) => {
    const message =
      "To proceed with your selected plan, you must first choose a project for the plan to apply to. This step is required to activate and access your project.";

    // Only include `sid` for plans that are not 'Free'
    const queryParams = planId ? `?sid=${planId}` : "";

    navigate(`/dashboard/campaigns${queryParams}`, {
      state: { showModal: true, message },
    });
  };

  const redirectToCheckout = (planId: string, campaignId: string) => {
    if (planId === "free") {
      // If the plan is "Free", use redirectToCampaigns instead
      redirectToCampaigns(null);
    } else {
      // Otherwise, proceed to the checkout page
      navigate(`/checkout?ccid=${campaignId}&sid=${planId}`);
    }
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  // Plan-specific routing logic
  if (planDetails.title === "Business") {
    // If Business plan, redirect to the contact page
    window.location.href = "/contact";
  } else if (!token) {
    // If not authenticated, redirect to login
    redirectToLogin();
  } else if (ccid) {
    // If authenticated and campaign ID exists, navigate to checkout
    redirectToCheckout(planDetails._id, ccid);
  } else if (planDetails.title === "Free") {
    // If Free plan, navigate to campaigns with a modal message
    redirectToCampaigns(null);
  } else {
    // For other plans (not Free or Business), redirect to campaigns
    redirectToCampaigns(planDetails._id);
  }
};
