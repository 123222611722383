import FooterSection from "../components/Footer/Footer";
import Navbar from "../components/Navbar";
import TrustedPartners from "../components/TrustedPartners";
import FaqCTA from "../components/FaqCTA";
import { pricingAssets } from "../utils/assets";
import ImageWrapper from "../components/ImageWrapper";
import Plan from "../components/pricing/Plan";
import {
  AccelerateGrowth,
  MaximizeImpact,
  SeamlessControl,
} from "../components/SvgIcons";
import PricingFAQ from "../components/PricingFAQ";

interface PricingItemProps {
  text: string;
  icon: React.ReactNode;
}

const PricingItem: React.FC<PricingItemProps> = ({ text, icon }) => {
  return (
    <div className="relative flex flex-col items-center space-y-2">
      <div className="flex items-center space-x-3">
        {icon}
        <p className="text-sm sm:text-base">{text}</p>
      </div>
      <img
        src={`${process.env.REACT_APP_S3_URL}/website-assets/PricingPage/underline.webp`}
        alt="underline"
        className="absolute top-7 sm:right-[-12%] sm:top-1"
      />
    </div>
  );
};

const Pricing: React.FC = () => {
  return (
    <div className="relative overflow-y-hidden bg-codGray font-plusJakartaSans text-mercury">
      <div className="relative w-full">
        <div className="z-0">
          {pricingAssets.map((asset, index) => (
            <ImageWrapper
              key={index}
              src={asset.src}
              alt={asset.alt}
              className={asset.className}
            />
          ))}
        </div>
        <Navbar />
        <div className="relative z-10 px-6 py-10 sm:p-10 md:px-12 md:py-20 xl:px-20">
          <h4 className="mb-6 mt-4 text-center font-plusJakartaSans text-2xl text-silverChalice lg:text-3xl">
            Pricing
          </h4>
          <h2 className="flex items-center justify-center text-center font-epilogue text-3xl font-bold md:text-4xl xl:text-5xl">
            Select Your Plan, Elevate Your Game.
          </h2>
          <p className="mx-auto my-6 w-[90%] text-center font-plusJakartaSans text-base text-silverChalice md:w-[80%] md:text-lg lg:text-xl xl:w-[50%]">
            Choose a plan that fits your needs and unlock the power of gamified
            digital marketing to engage, convert, and grow your business.
          </p>
          <div className="flex flex-col items-center justify-center font-plusJakartaSans font-semibold">
            <div className="flex items-center sm:space-x-10">
              <PricingItem text="Maximize Impact" icon={<MaximizeImpact />} />
              <PricingItem text="Seamless Control" icon={<SeamlessControl />} />
              <PricingItem
                text="Accelerate Growth"
                icon={<AccelerateGrowth />}
              />
            </div>
          </div>
        </div>

        <Plan />

        <h4 className="text-center font-plusJakartaSans font-semibold sm:text-lg md:text-xl lg:pb-8 lg:text-2xl">
          Brands winning at the game with us
        </h4>
        <TrustedPartners />
        <div className="px-6 py-10 sm:p-10 md:px-12 md:py-16 xl:px-20 xl:py-16">
          <FaqCTA />
        </div>
        <PricingFAQ />
        <FooterSection />
      </div>
    </div>
  );
};

export default Pricing;
