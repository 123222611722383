export const businessPlan = {
  _id: "business",
  title: "Business",
  description: "Tailored Plans to Fit Your Unique Business Needs",
  pricing: {
    US: 0,
    IN: 0,
    isFree: false,
    discount: 0,
    validForInDays: 0,
  },
  features: ["Marketing agencies", "Event agencies", "HR", "Institutions"],
  settings: {
    freeTrialMonth: false,
    buttonText: "Chat with us",
    highlight: false,
    tag: "",
  },
};
