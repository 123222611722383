import React from "react";
import LazyLoadImages from "../LazyLoadImages";
import {
  CallIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  LocationIcon,
  MailIcon,
} from "../SvgIcons";
import "../../styles/Sections/Footer.css";
import FooterLink from "./FooterLink";
import { useMediaQuery } from "react-responsive";

const { REACT_APP_S3_URL } = process.env;

const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

interface SocialIconProps {
  href?: string;
  Icon: React.FC<{ width?: number; height?: number }>;
}

const SocialIcon: React.FC<SocialIconProps> = ({ href, Icon }) => {
  const isAboveXl = useMediaQuery({ query: "(min-width: 1024px)" });

  const iconSize = isAboveXl
    ? { width: 20, height: 20 }
    : { width: 16, height: 16 };

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Icon width={iconSize.width} height={iconSize.height} />
    </a>
  );
};

const ContactInfo: React.FC<{ href: string; Icon: React.FC; text: string }> = ({
  href,
  Icon,
  text,
}) => (
  <a href={href} className="mb-3 flex items-center text-white hover:opacity-80">
    <div className="hidden sm:block">
      <Icon />
    </div>
    <span className="text-sm text-mercury sm:ml-3">{text}</span>
  </a>
);

const FooterSection: React.FC = () => {
  const services = [
    { label: "No Code Game Builder", to: "/service#no-code-game-builder" },
    { label: "In App Gamification", to: "/service#in-app-gamification" },
    { label: "Customized Games", to: "/service#customized-games" },
    { label: "Web Development", to: "/service#web-development" },
  ];

  const popularGames = [
    { label: "Spin the Wheel", to: "/game/spin-the-wheel" },
    { label: "Quiz", to: "/game/quiz" },
    { label: "Jigsaw Puzzle", to: "/game/jigsaw-puzzle" },
    { label: "Memory Card", to: "/game/memory-card" },
  ];

  const companyLinks = [
    { label: "About Us", to: "/about-us" },
    { label: "Pricing", to: "/pricing" },
    { label: "FAQs", to: "/faq" },
    { label: "Contact Us", to: "/contact" },
  ];

  return (
    <section className="w-full bg-mineShaft px-6 py-8 text-mercury sm:px-10 sm:py-8 md:px-12 md:py-10 xl:px-20 xl:py-12">
      <div className="flex items-center justify-between gap-x-10 xl:gap-x-20">
        <div className="hidden lg:block lg:w-[20%] xl:w-[40%]">
          <LazyLoadImages
            className="cursor-pointer xl:h-8"
            onClick={scrollToTop}
            src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
            alt="workplay-logo"
          />
          <div className="my-4 h-[0.5px] w-full bg-[#585858] opacity-50"></div>
          <div className="flex items-center space-x-2">
            <SocialIcon Icon={LocationIcon} />
            <p className="text-sm text-mercury">
              No.13-14, 2nd floor, Kothnur Main Rd, RBI Layout, JP Nagar 7th
              Phase, Bengaluru - 560078
            </p>
          </div>
          <ul className="items-center justify-between text-silverChalice lg:mt-4 xl:mt-6 xl:flex">
            <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
            <FooterLink to="/terms-of-use">Terms of Use</FooterLink>
            <FooterLink to="/sitemap">Site Map</FooterLink>
          </ul>
          <p className="mt-4 text-sm text-[#585858] ">
            © 2024 Workplay studio pvt ltd - All Rights Reserved
          </p>
        </div>

        {/* Right Section */}
        <div className="grid w-full grid-cols-2  justify-items-start gap-y-8 sm:gap-y-10 lg:grid-cols-4 lg:justify-items-end lg:gap-y-0">
          {/* Social Icons & Contact */}
          <div className="order-last flex flex-col justify-between space-y-4 lg:order-none lg:space-y-10">
            <div>
              <p className="pb-2 font-medium sm:pb-3 sm:text-lg xl:pb-3">
                Follow us
              </p>
              <div className="flex items-center space-x-4">
                <SocialIcon
                  href="https://www.linkedin.com/company/workplaystudio/"
                  Icon={LinkedinIcon}
                />
                <SocialIcon
                  href="https://www.facebook.com/people/WorkPlay-Studio/100075423147764/"
                  Icon={FacebookIcon}
                />
                <SocialIcon
                  href="https://www.instagram.com/workplaystudio/"
                  Icon={InstagramIcon}
                />
              </div>
            </div>
            <div>
              <p className="pb-2 font-medium sm:pb-3 sm:text-lg xl:pb-3">
                Get in touch
              </p>
              <ContactInfo
                href="tel:+919742847450"
                Icon={CallIcon}
                text="+91 97428 47450"
              />
              <ContactInfo
                href="mailto:info@workplay.digital"
                Icon={MailIcon}
                text="info@workplay.digital"
              />
            </div>
          </div>

          {/* Services */}
          <div className="order-1 lg:order-none">
            <p className="pb-3 font-medium sm:text-lg lg:pb-7 xl:pb-9">
              Service
            </p>
            <ul className="space-y-2 text-sm text-silverChalice sm:space-y-3 sm:text-[15px] lg:space-y-4">
              {services.map(({ label, to }) => (
                <FooterLink key={label} to={to}>
                  {label}
                </FooterLink>
              ))}
            </ul>
          </div>

          {/* Popular Games */}
          <div className="order-1 lg:order-none">
            <p className="pb-3 font-medium sm:text-lg lg:pb-7 xl:pb-9">
              Popular Games
            </p>
            <ul className="space-y-2 text-sm text-silverChalice sm:space-y-3 sm:text-[15px] lg:space-y-4">
              {popularGames.map(({ label, to }) => (
                <FooterLink key={label} to={to}>
                  {label}
                </FooterLink>
              ))}
            </ul>
          </div>

          {/* Company Links */}
          <div className="order-2 lg:order-none">
            <p className="pb-3 font-medium sm:text-lg lg:pb-7 xl:pb-9">
              Company
            </p>
            <ul className="space-y-2 text-sm text-silverChalice sm:space-y-3 sm:text-[15px] lg:space-y-4">
              {companyLinks.map(({ label, to }) => (
                <FooterLink key={label} to={to}>
                  {label}
                </FooterLink>
              ))}
            </ul>
          </div>
        </div>
      </div>{" "}
      <div className="block pt-12 lg:hidden">
        <LazyLoadImages
          className="mx-auto h-6 cursor-pointer"
          onClick={scrollToTop}
          src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
          alt="workplay-logo"
        />
        <div className="my-4 h-[0.5px] w-full bg-[#585858] opacity-60"></div>
        <div className="flex items-center justify-center space-x-2">
          <SocialIcon Icon={LocationIcon} />
          <p className="text-sm text-mercury">
            No.13-14, 2nd floor, Kothnur Main Rd, RBI Layout, JP Nagar 7th
            Phase, Bengaluru - 560078
          </p>
        </div>
        <ul className="mt-4 flex items-center justify-between text-sm text-silverChalice">
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink>Terms of Use</FooterLink>
          <FooterLink>Site Map</FooterLink>
        </ul>
        <p className="mt-2 text-center text-xs text-[#585858]">
          © 2024 Workplay studio pvt ltd - All Rights Reserved
        </p>
      </div>
    </section>
  );
};

export default FooterSection;
