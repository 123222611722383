import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { HashLink } from "react-router-hash-link";
import UserAvatar from "./UserProfile/UserAvatar";
import { LoginContext } from "./Context/LoginContext/LoginContext";
import { userContext } from "./Context/LoginContext/UserContext";
import LazyLoadImages from "./LazyLoadImages";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { MenuIcon } from "./SvgIcons";

const { REACT_APP_S3_URL, REACT_APP_API_URL, REACT_APP_WORKPLAY_URL } =
  process.env;

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const { user, setUser } = useContext(userContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServiceDropOpen, setIsServiceDropOpen] = useState(false);
  const [isServiceMobileDropOpen, setIsServiceMobileDropOpen] = useState(false);
  const servicedropdownRef = useRef<HTMLUListElement | null>(null);
  const serviceMenuRef = useRef<HTMLDivElement | null>(null);

  const toggleServiceDropdown = () => {
    setIsServiceDropOpen(!isServiceDropOpen);
  };

  const toggleServiceMobileDropdown = () => {
    setIsServiceMobileDropOpen(!isServiceMobileDropOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    const bodyElement = document.body;
    bodyElement.classList.toggle("mobile-menu-open", isMobileMenuOpen);
    const gameCards = document.querySelectorAll(".game-card");
    const socials = document.querySelectorAll("#footer");
    const contact = document.querySelectorAll("#contact");
    gameCards.forEach((card) => {
      if (card instanceof HTMLElement) {
        card.style.display = isMobileMenuOpen ? "block" : "none";
      }
    });
    socials.forEach((card) => {
      if (card instanceof HTMLElement) {
        card.style.display = isMobileMenuOpen ? "block" : "none";
      }
    });
    contact.forEach((card) => {
      if (card instanceof HTMLElement) {
        card.style.display = isMobileMenuOpen ? "block" : "none";
      }
    });
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("formData");
    localStorage.removeItem("xSudTzMYmd");
    contextValue?.logout();
    sessionStorage.removeItem(" LazyLoadImagesLink");
    sessionStorage.removeItem("fname");
    sessionStorage.removeItem("lname");
    sessionStorage.removeItem("collapse");
    sessionStorage.removeItem("email");
    localStorage.clear();
    setIsMobileMenuOpen(false);
  };

  const handleProfile = () => {
    navigate("dashboard/profile");
    setIsMobileMenuOpen(false);
  };

  const handleRedirectToDashboard = () => {
    navigate("/dashboard/campaigns");
    setIsMobileMenuOpen(false);
  };

  const handleSetUser = (data: any) => {
    setUser({
      email: data.email,
      fname: data.firstName,
      lname: data.lastName,
      phone: data.phoneNumber,
      isdcode: data.isdCode,
      img: {
        source: data.LazyLoadImages?.source ?? "default", // Use optional chaining and default value
        link:
          data.LazyLoadImages?.source === "bucket"
            ? `${REACT_APP_S3_URL}/uploads/profile/${data.LazyLoadImages?.link}`
            : data.LazyLoadImages?.link ?? "", // Default to an empty string if link is missing
      },
      bio: data.bio,
      location: data.location,
      socials: data.socials,
      company: data.company,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        serviceMenuRef.current &&
        !serviceMenuRef.current.contains(event.target as Node) &&
        servicedropdownRef.current &&
        !servicedropdownRef.current.contains(event.target as Node)
      ) {
        setIsServiceDropOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const token = localStorage.getItem("xSudTzMYmd");

    const fetchProfile = async (retry = false) => {
      const URL = `${REACT_APP_API_URL}/auth/profile`;
      if (!URL) {
        return;
      }

      try {
        const response = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success === true) {
          contextValue?.login();
          const userData = response.data.data;

          // Check if LazyLoadImages exists before accessing its properties
          if (userData?.LazyLoadImages?.source) {
            handleSetUser(userData);

            if (userData.LazyLoadImages.source === "oauth") {
              localStorage.setItem(
                "LazyLoadImagesLink",
                userData.LazyLoadImages.link,
              );
            } else if (userData.LazyLoadImages.source === "bucket") {
              localStorage.setItem(
                "LazyLoadImagesLink",
                `${REACT_APP_S3_URL}/uploads/profile/${userData.LazyLoadImages.link}`,
              );
            }
          } else {
            // Retry fetching after a delay if LazyLoadImages is missing
            if (!retry) {
              setTimeout(() => fetchProfile(true), 1000);
            }
          }
        }
      } catch (error: any) {
        contextValue?.logout();
        localStorage.clear();
        sessionStorage.clear();
        console.log(error);
      }
    };

    if (token) {
      fetchProfile();
    }
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };
  const handleLogin = () => {
    setTimeout(() => {
      navigate("/login");
    }, 400);
    setIsMobileMenuOpen(false);
  };

  const handleSignUp = () => {
    setTimeout(() => {
      navigate("/signup");
    }, 400);
    setIsMobileMenuOpen(false);
  };

  const handleGamePageRedirect = () => {
    window.location.href = `${REACT_APP_WORKPLAY_URL}/game`;
  };

  return (
    <>
      <div
        className={`flex items-center justify-between p-6 text-mercury sm:px-10 md:px-12 xl:px-20 xl:py-[30px] ${isMobileMenuOpen ? "bg-mineShaft" : ""}`}
      >
        <div className="menubar z-100 w-full md:w-[25%] xl:w-[35%]">
          <LazyLoadImages
            className="z-100 h-6 cursor-pointer xl:h-7"
            src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
            alt="workplay-logo"
            onClick={handleLogoRedirect}
          />
          <div className="z-100 block md:hidden" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <X size={24} /> : <MenuIcon width={24} />}
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className={`absolute left-0 top-[70px] z-100 h-screen w-full bg-mineShaft px-6 py-8 text-white transition-transform duration-300 ease-in-out ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } md:hidden`}
        >
          <ul className="space-y-5">
            <li onClick={closeMobileMenu}>
              <Link to="/" className="block text-lg hover:opacity-80">
                Home
              </Link>
            </li>
            <li>
              <div className="group relative">
                <div
                  className="flex items-center justify-between text-lg hover:opacity-80"
                  onClick={toggleServiceMobileDropdown}
                >
                  Our Services
                  {isServiceMobileDropOpen ? <ChevronUp /> : <ChevronDown />}
                </div>
                {isServiceMobileDropOpen && (
                  <ul className="mt-2 space-y-3 pl-4">
                    <li className="hover:opacity-80">
                      <Link
                        to="/service#no-code-game-builder"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        No Code Game Builder
                      </Link>
                    </li>
                    <li className="hover:opacity-80">
                      <Link
                        to="/service#in-app-gamification"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        In App Gamification
                      </Link>
                    </li>
                    <li className="hover:opacity-80">
                      <Link
                        to="/service#customized-games"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        Customized Games
                      </Link>
                    </li>
                    <li className="hover:opacity-80">
                      <Link
                        to="/service#web-development"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        Web Development
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <li onClick={closeMobileMenu}>
              <HashLink
                to="/about-us"
                className="block text-lg hover:opacity-80"
              >
                About Us
              </HashLink>
            </li>
            <li
              onClick={() => {
                closeMobileMenu();
                window.location.href = "/pricing";
              }}
            >
              <HashLink className="block text-lg hover:opacity-80">
                Pricing
              </HashLink>
            </li>
            <li onClick={closeMobileMenu}>
              <Link to="/contact" className="block text-lg hover:opacity-80">
                Contact
              </Link>
            </li>
          </ul>

          {/* Buttons */}
          <div className="mt-5 space-y-6">
            {!localStorage.getItem("xSudTzMYmd") ? (
              <>
                <button
                  onClick={handleGamePageRedirect}
                  className="headerBtn block w-full rounded-full bg-tanHide py-2 text-center text-base font-medium text-white shadow-navBtn hover:bg-brandyPunch"
                >
                  Play Now
                </button>
                <button
                  onClick={handleLogin}
                  className="headerBtn block w-full rounded-full bg-merlin py-2 text-center text-base font-medium text-white shadow-navBtnLogin hover:bg-tuatara"
                >
                  Login
                </button>
              </>
            ) : (
              <ul className="space-y-5">
                <li
                  onClick={handleProfile}
                  className="block text-lg hover:underline"
                >
                  Profile
                </li>
                <li
                  onClick={handleRedirectToDashboard}
                  className="block text-lg hover:underline"
                >
                  Dashboard
                </li>
                <li
                  onClick={handleLogout}
                  className="headerBtn block w-full rounded-full bg-tanHide py-2 text-center text-base font-medium text-white shadow-navBtn hover:bg-brandyPunch"
                >
                  Logout
                </li>
              </ul>
            )}
          </div>
        </div>
        <div
          className={`z-100 hidden items-center justify-between font-plusJakartaSans text-mercury md:flex md:w-[75%] ${isMobileMenuOpen ? "open" : ""} `}
        >
          <ul className="flex items-center md:space-x-2 md:text-sm lg:space-x-8 lg:text-base xl:space-x-10">
            <Link to="/">
              <li className="underline-offset-4 hover:opacity-80">Home</li>
            </Link>
            <HashLink>
              <li className="group relative flex items-center">
                <div
                  ref={serviceMenuRef}
                  onClick={toggleServiceDropdown}
                  className={`flex cursor-pointer items-center space-x-2 ${
                    isServiceDropOpen ? "underline" : ""
                  } hover:opacity-80`}
                >
                  <span className="underline-offset-4">Our Services</span>
                  {isServiceDropOpen ? (
                    <ChevronUp size={16} />
                  ) : (
                    <ChevronDown size={16} />
                  )}
                </div>
                {/* Dropdown menu */}
                <ul
                  ref={servicedropdownRef}
                  className={`${
                    isServiceDropOpen ? "block" : "hidden"
                  } absolute left-0 top-6 z-100 mt-2 h-fit w-52 bg-mineShaft shadow-lg transition-all duration-200 ease-in-out`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Link
                    to="/service#no-code-game-builder"
                    onClick={() => setIsServiceDropOpen(false)}
                  >
                    <li className="px-4 py-2 hover:opacity-80">
                      No Code Game Builder
                    </li>
                  </Link>

                  <Link
                    to="/service#in-app-gamification"
                    onClick={() => setIsServiceDropOpen(false)}
                  >
                    <li className="px-4 py-2 hover:opacity-80">
                      In App Gamification
                    </li>
                  </Link>

                  <Link
                    to="/service#customized-games"
                    onClick={() => setIsServiceDropOpen(false)}
                  >
                    <li className="px-4 py-2 hover:opacity-80">
                      Customized Games
                    </li>
                  </Link>

                  <Link
                    to="/service#web-development"
                    onClick={() => setIsServiceDropOpen(false)}
                  >
                    <li className="px-4 py-2 hover:opacity-80">
                      Web Development
                    </li>
                  </Link>
                </ul>
              </li>
            </HashLink>
            <HashLink to="/about-us" onClick={closeMobileMenu}>
              <li className="underline-offset-4 hover:opacity-80">About Us</li>
            </HashLink>
            <HashLink
              onClick={() => {
                closeMobileMenu();
                window.location.href = "/pricing";
              }}
            >
              <li>Pricing</li>
            </HashLink>
            <Link to="/contact" onClick={closeMobileMenu}>
              <li className="underline-offset-4 hover:opacity-80">Contact</li>
            </Link>
          </ul>
          {!localStorage.getItem("xSudTzMYmd") ? (
            <div className="flex h-[40px] md:space-x-2 lg:space-x-4">
              <button
                className="headerBtn w-24 cursor-pointer rounded-full bg-tanHide font-encodeSansExpanded text-sm font-medium text-white shadow-navBtn hover:bg-brandyPunch lg:w-28"
                onClick={handleGamePageRedirect}
              >
                Play Now
              </button>
              <button
                className="headerBtn w-24 cursor-pointer rounded-full bg-merlin font-encodeSansExpanded text-sm font-medium text-white shadow-navBtnLogin hover:bg-tuatara lg:w-28"
                onClick={handleLogin}
              >
                Login
              </button>{" "}
            </div>
          ) : !isMobileMenuOpen && localStorage.getItem("xSudTzMYmd") ? (
            <div className="h-[40px]">
              <UserAvatar />
            </div>
          ) : (
            <ul>
              <li onClick={handleProfile}>Profile</li>
              <li onClick={handleRedirectToDashboard}>Dashboard</li>
              <Link to="/" onClick={handleLogout}>
                <li className="nav-logout">Logout</li>
              </Link>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
