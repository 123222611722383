import React from "react";
import { useDispatch } from "react-redux";
import { setPlan } from "../../features/planSlice";
import { TickIcon } from "../SvgIcons";
import { X } from "react-feather";
import { PlanDetails } from "../../types/planTypes";
import { businessPlan } from "../../data/businessPlan";
import { freePlan } from "../../data/freePlan";
import { useLocation, useNavigate } from "react-router-dom";
import { handlePriceButtonClick } from "../../utils/pricingNavigationUtils";

interface PlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  planDetails: PlanDetails;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PlanDetails | null>>;
  allPlanTitles: string[];
  subscriptions: PlanDetails[];
}

const PlanModal: React.FC<PlanModalProps> = ({
  isOpen,
  onClose,
  planDetails,
  allPlanTitles,
  setSelectedPlan,
  subscriptions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ccid = searchParams.get("ccid");

  if (!isOpen) return null;
  const pricingDetails = JSON.parse(
    localStorage.getItem("pricingdetail") || "[]",
  );
  const country = pricingDetails[0]?.country || "US";
  const trialAvail = localStorage.getItem("trialAvail");

  const allTitles =
    trialAvail === "true"
      ? ["Free", ...allPlanTitles, "Business"]
      : [...allPlanTitles, "Business"];

  const handleTitleClick = (title: string) => {
    const selected = subscriptions.find(
      (subscription) => subscription.title === title,
    );
    if (selected) {
      dispatch(setPlan(selected));
      setSelectedPlan(selected);
    } else if (title === "Business") {
      dispatch(setPlan(businessPlan));
      setSelectedPlan(businessPlan);
    } else if (title === "Free") {
      dispatch(setPlan(freePlan));
      setSelectedPlan(freePlan);
    }
  };

  const handlePriceClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handlePriceButtonClick(e, navigate, ccid, planDetails);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-mercury bg-opacity-30">
      <div className="relative w-11/12 max-w-xl rounded-[20px] bg-[#1C1C1E] p-8 text-white shadow-lg">
        <X
          className="absolute right-4 top-4 cursor-pointer text-[#F54A69] hover:text-red-600"
          onClick={onClose}
        />
        <div className="mb-8 flex space-x-8 pb-1 text-lg">
          {allTitles.map((title) => (
            <button
              key={title}
              onClick={() => handleTitleClick(title)}
              className={`${
                planDetails?.title === title
                  ? "border-b border-white font-semibold"
                  : ""
              }`}
            >
              {title}
            </button>
          ))}
        </div>
        {/* <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">{planDetails?.title} Plan</h2>
          {planDetails.pricing.discount !== 0 && (
            <p className="rounded-full bg-[#D07C32] px-3 py-1.5 text-[15px] font-medium text-white">
              - {planDetails.pricing.discount}% off
            </p>
          )}
        </div> */}

        {(country === "IN" || country !== "IN") &&
        planDetails.pricing[country === "IN" ? "IN" : "US"] ? (
          <p
            className={`pt-2 text-lg font-bold text-silverChalice line-through ${
              planDetails.pricing.discount === 0 ? "opacity-0" : "opacity-100"
            }`}
          >
            {country === "IN"
              ? `₹${planDetails.pricing.IN}`
              : `$${planDetails.pricing.US}`}
          </p>
        ) : null}

        {(country === "IN" || country !== "IN") &&
        planDetails.pricing[country === "IN" ? "IN" : "US"] ? (
          <div className="flex items-end gap-x-1">
            <p className="text-2xl font-bold text-white">
              {country === "IN"
                ? `₹${planDetails.pricing.IN - (planDetails.pricing.IN * planDetails.pricing.discount) / 100}`
                : `$${planDetails.pricing.US - (planDetails.pricing.US * planDetails.pricing.discount) / 100}`}
            </p>
            <p className="text-lg font-medium text-mercury">
              / {planDetails.pricing.validForInDays} days{" "}
              <span className="ml-1 text-sm text-silverChalice">
                {" "}
                (Inc. Tax)
              </span>
            </p>
          </div>
        ) : null}

        {planDetails.title === "Business" && (
          <p className="mb-1 mt-4 text-2xl font-bold text-white">
            Customized Plan
          </p>
        )}

        {planDetails.title === "Free" && (
          <div className="flex items-center space-x-1 pt-9">
            <p className="text-2xl font-bold text-white">
              {country === "IN" ? `₹0` : `$0`}
            </p>
            <p className="text-lg font-medium text-mercury">/ 30 days</p>
          </div>
        )}

        <p className="mt-4 text-mercury">{planDetails?.description}</p>

        {planDetails?.features && planDetails.features.length > 0 ? (
          <>
            {planDetails.title === "Business" ? (
              <p className="my-3 text-[#c5c5c5]">Markets We Serve:</p>
            ) : (
              <p className="my-3 text-[#c5c5c5]">This Subscription includes:</p>
            )}
            <div className="grid gap-4 text-sm text-gray-300 md:grid-cols-2">
              {planDetails.features.map((feature: string, index: number) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 text-[#c5c5c5]"
                >
                  <TickIcon width={20} height={20} />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="text-[#c5c5c5]">No features available for this plan.</p>
        )}

        <div className="flex items-center justify-center">
          <button
            className="mt-10 w-[65%] rounded-full bg-[#F8A359] py-2 font-medium text-white hover:bg-[#ffa564]"
            onClick={handlePriceClick}
          >
            {planDetails?.settings.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanModal;
