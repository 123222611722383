import { useEffect, useState } from "react";
import { BulletPointsIcon } from "./SvgIcons";
import { Plus, X } from "react-feather";

const faqItems = [
  {
    question: "What are your pricing plans?",
    answer:
      "We offer four pricing plans: the Free plan, which gives you 30 days of free access for one-time account use, the Basic plan with a monthly subscription, the Pro plan with an 90-day subscription, and the Business plan with custom pricing for large enterprises.",
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, we offer a one-time 30-day free trial per account on any project, so you can try all features before choosing a paid plan.",
  },
  {
    question: "Do I need a credit card to start the free trial?",
    answer:
      "No, you do not need a credit card to start your free trial. You can simply sign up and get started with no obligation.",
  },
  {
    question: "Are there any additional fees or hidden costs?",
    answer:
      "No, there are no hidden fees. The price you see for each plan is all-inclusive, and you’ll only be charged for the plan and any extra services (if applicable).",
  },
  {
    question: "Do you offer discounts for long-term commitments?",
    answer:
      "Yes, we offer discounted rates for pro subscription. Check out our pricing page for more details on long-term plans.",
  },
  {
    question: "Can I cancel my subscription at any time?",
    answer:
      "Yes, you can cancel your subscription at any time. If you cancel before the next billing cycle, you won’t be charged for the following month. If a project is on a plan and you wish to cancel, you can disable the project to stop any further charges.",
  },
  {
    question: "Can I request a custom pricing plan?",
    answer:
      "If you have specific needs or require a large number of users, we offer a Business plan. Please contact our sales team to discuss your requirements, and we’ll provide a tailored quote.",
  },
  // {
  //   question: "How do I update my payment information?",
  //   answer:
  //     "To update your payment information, simply go to the billing section of your account settings and follow the prompts to add or update your payment method.",
  // },
  {
    question: "Is the pricing per user or per project?",
    answer:
      "Our pricing is based on the number of projects. You need to purchase a plan for each project. Please refer to the specific details on our pricing page for more information on the available plans and project-based pricing.",
  },
  {
    question: "Will I get a refund if I cancel my subscription early?",
    answer:
      "We do not offer refunds once you have subscribed. However, you will continue to have access to the service until the end of your current plan. You can deactivate your account after that to avoid further charges.",
  },
];

const PricingFAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

  const toggleQuestion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const [iconSize, setIconSize] = useState({ width: 16, height: 16 });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setIconSize({ width: 30, height: 30 });
      } else if (width >= 640) {
        setIconSize({ width: 24, height: 24 });
      } else {
        setIconSize({ width: 16, height: 16 });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFaq = () => {
    window.location.href = "/faq";
  };

  return (
    <div className="relative p-16 px-6 sm:px-10 md:px-12 xl:px-20">
      <div className="ellipse absolute bottom-[50%] right-[30%]"></div>
      <h3 className="relative z-20 pb-10 text-center font-epilogue text-3xl font-bold sm:text-4xl md:text-5xl">
        Frequently asked questions
      </h3>
      <img
        src={`${process.env.REACT_APP_S3_URL}/website-assets/PricingPage/curly-pattern.webp`}
        alt="greenEllipse"
        className="absolute inset-0 left-[10%] top-[-5%] z-0 h-16 sm:h-20 lg:h-auto xl:left-[20%]"
      />
      {faqItems.map((item, index) => (
        <div
          key={index}
          className="relative z-20 mx-auto my-6 md:my-6 md:w-[80%] xl:my-8 xl:w-[60%]"
        >
          <div
            onClick={() => toggleQuestion(index)}
            className="flex min-h-[20px] cursor-pointer flex-row justify-between font-semibold sm:items-center"
          >
            <div className="flex space-x-2 sm:items-center md:space-x-3">
              <BulletPointsIcon
                fill={["#D2C03A", "#8ACF62", "#F54A69", "#3BB5FB"][index % 4]}
                width={iconSize.width}
                height={iconSize.height}
              />
              <span className="text-left font-plusJakartaSans font-normal text-mercury md:text-lg xl:text-xl">
                {item.question}
              </span>
            </div>
            {expandedIndex === index ? <X size={20} /> : <Plus size={20} />}
          </div>
          {expandedIndex === index && (
            <div
              className="my-2 ml-10 min-h-[20px] text-left font-plusJakartaSans text-[15px] text-silverChalice text-opacity-80 opacity-100 transition-all duration-300 ease-in-out md:text-[17px] xl:text-lg"
              style={{
                maxHeight: expandedIndex === index ? "500px" : "0",
                overflow: "hidden",
              }}
            >
              {item.answer}
            </div>
          )}
        </div>
      ))}
      <p className="flex flex-col items-center justify-center pt-6 text-center font-plusJakartaSans sm:flex-row sm:py-4 sm:text-lg lg:pb-14 lg:pt-8 lg:text-xl">
        More Question?{" "}
        <div
          onClick={handleFaq}
          className="underline-link relative ml-3 mt-2 w-fit scale-105 cursor-pointer hover:opacity-80 sm:mt-0"
        >
          <strong className="win relative font-plusJakartaSans font-semibold">
            Go through FAQs
          </strong>
        </div>
      </p>
    </div>
  );
};

export default PricingFAQ;
