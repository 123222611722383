import React from "react";
import LazyLoadImages from "../../../components/LazyLoadImages";

type GameCardProps = {
  title: string;
  description: string;
  imageSrc: string;
  buttonLinkPlay: string;
  buttonLinkCustomize?: string;
  imageBgSrc: string;
  link: string;
};

const Card: React.FC<GameCardProps> = ({
  title,
  description,
  imageSrc,
  buttonLinkPlay,
  buttonLinkCustomize,
  imageBgSrc,
  link,
}) => {
  return (
    <div className="relative h-auto rounded-2xl bg-card-gradient p-6 md:p-8">
      {buttonLinkCustomize && (
        <div className="absolute right-[-18px] top-4 z-50 p-2">
          <div className="relative rounded-l-md rounded-br-sm rounded-tr-md bg-[#D07C32] px-3 py-1 font-bold text-mercury sm:text-lg">
            Customizable
          </div>
          <div className="absolute right-2 top-[40px] h-0 w-0 border-r-[10px] border-t-[10px] border-r-transparent border-t-[#D07C32] sm:top-[43px]"></div>
        </div>
      )}
      <div className="relative z-20">
        <img src={imageSrc} alt={title} className="mx-auto w-36 md:w-fit" />
        <p className="mb-3 mt-10 font-epilogue text-xl font-semibold text-mercury sm:text-2xl">
          {title}
        </p>

        <p className="pb-6 font-plusJakartaSans font-medium text-silverChalice">
          {description}{" "}
          <span
            className="pl-1 text-[15px] text-stone-300 underline underline-offset-2 hover:cursor-pointer"
            onClick={() => window.open(link, "_blank")}
          >
            Learn More
          </span>
        </p>

        <div className="flex items-center space-x-2 md:space-x-4">
          <a
            href={buttonLinkPlay}
            target="_blank"
            rel="noopener noreferrer"
            className="headerBtn relative w-fit rounded-full bg-tanHide px-4 py-2 font-encodeSansExpanded text-sm font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-base md:px-6"
          >
            Play Now
          </a>
          {buttonLinkCustomize && (
            <a
              href={buttonLinkCustomize}
              target="_blank"
              rel="noopener noreferrer"
              className="headerBtn relative w-fit rounded-full bg-merlin px-4 py-2 font-encodeSansExpanded text-sm font-semibold text-white shadow-navBtnLogin hover:bg-tuatara sm:text-base md:px-6"
            >
              Customize
            </a>
          )}
        </div>
      </div>
      <LazyLoadImages
        src={imageBgSrc}
        alt={title}
        className="absolute bottom-0 right-0 z-0 h-full w-full rounded-r-[22px]"
      />
    </div>
  );
};

export default Card;
